import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { HashLink } from "react-router-hash-link";

import BlockchainInOilAndGas from "../../../assets/images/IndustrysolutionsPage/blockchain-in-oil-and-gas.webp";
import CommonButton from "../../../components/CommonButton.js";
import Underline from "../../../assets/images/HomePage/underline.webp";
function OilAndGas() {
  return (
    // new code

    <Flex mt={{ base: "20px", md: "80px", lg: "200px" }} width={"100%"}>
      {/* title */}
      <Flex
        mx={"auto"}
        flexDir={{ base: "column-reverse", md: "row", lg: "row" }}
      >
        <Flex
          width={{ base: "100%", md: "60%", lg: "50%" }}
          flexDir={"column"}
          ml={{ base: "6%", md: "6%", lg: "10%" }}
        >
          <Text
            className="heading"
            color={"#94DAF8"}
            fontSize={{ base: "24px", md: "38px", lg: "45px" }}
          >
            Blockchain in Oil & Gas
          </Text>
          <Image
            mt={"-10px"}
            ml={"-10px"}
            width={"70px"}
            src={Underline}  alt="underline"
          ></Image>

          <Text
            mt={{ base: "30px", md: "40px", lg: "80px" }}
            pr={{ base: "40px", md: "30px", lg: "30px" }}
            maxW={{ base: "", md: "380px", lg: "650px" }}
            fontSize={{ base: "14px", md: "14px", lg: "16px" }}
            color={"white"}
            className="content_font"
            fontWeight={"light"}
          >
            Oil and gas is a massive industry in the global economy that
            integrates multiple enterprises. Processing such huge amounts of
            data in real time with accuracy and efficiency is a tedious task.
            Blockchain provides tamper-proof records of accounting and
            transactional data. It ensures transparency in the bulk
            transportation supply chain.
          </Text>
          <HashLink smooth to={"/contact-us#contact"}>
            <CommonButton
            width="100px"
              btn="Book a Demo"
              mt={{ base: "30px", md: "60px", lg: "30px" }}
            />
          </HashLink>
        </Flex>
        <Flex p={"20px"} width={{ base: "100%", md: "60%", lg: "50%" }}>
          <Image
            my={{ base: "30px", md: "0px", lg: "0px" }}
            objectFit={"contain"}
            // srcSet={`${BlockchainInOilAndGasSmall} 1000w, ${BlockchainInOilAndGas} 2000w`}
            src={BlockchainInOilAndGas}
            width={"100%"}
            alt="Future of Oil & Gas with Blockchain"
            pointerEvents={"none"}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default OilAndGas;
