import {
  Box,
  Flex,
  Heading,
  Button,
  Image,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import Blk from "../../../assets/images/HomePage/blk.webp";
import ARVR from "../../../assets/images/HomePage/ar.webp";
import NFT from "../../../assets/images/HomePage/nft.webp";
import Soft from "../../../assets/images/HomePage/soft.webp";
import Underline from "../../../assets/images/HomePage/underline.webp";

import CommonButton from "../../CommonButton";

function WhatWeDo() {
  const [mute, setMute] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isFirstModalOpen,
    onOpen: onFirstModalOpen,
    onClose: onFirstModalClose,
  } = useDisclosure();
  const {
    isOpen: isSecondModalOpen,
    onOpen: onSecondModalOpen,
    onClose: onSecondModalClose,
  } = useDisclosure();
  const {
    isOpen: isThirdModalOpen,
    onOpen: onThirdModalOpen,
    onClose: onThirdModalClose,
  } = useDisclosure();
  const {
    isOpen: isForthModalOpen,
    onOpen: onForthModalOpen,
    onClose: onForthModalClose,
  } = useDisclosure();
  return (
    // new what we do

    <Flex
      flexDir={"column"}
      mb={{ base: "50px", md: "100px", lg: "50px" }}
      p={{ base: "20px" }}
      height={{ base: "fit-content", md: "fit-content", lg: "fit-content" }}
      width={"100%"}
    >
      {/* heading */}
      <Flex flexDir={"column"} ml={{ base: "6%", md: "6%", lg: "6%" }}>
        <Text
          className="heading"
          color={"#94DAF8"}
          fontSize={{ base: "24px", md: "38px", lg: "45px" }}
        >
          What We Do
        </Text> 
        <Image   alt="underline" mt={"-10px"} ml={"-10px"} width={"70px"} src={Underline}  ></Image>
      </Flex>
      {/* content */}

      <SimpleGrid
        mt={{ base: "90px", md: "80px", lg: "130px" }}
        mb={{ md: "50px", lg: "150px" }}
        spacing={{ base: 2, md: 5, lg: 10 }}
        columns={[2, null, 3]}
        mx={"auto"}
        width={{ base: "100%", md: "100%", lg: "80%" }}
      >
        {/* Managed Blockchain as a Service */}
        <Box
          mt={{ base: "80px", md: "0px", lg: "0px" }}
          _hover={{
            backgroundColor: "rgba(0, 0, 0, 0.3)", // Set the background color on hover
          }}
          cursor={"pointer"}
          overflow={"hidden"}
          backgroundColor={"rgba(0, 0, 0, 0.15)"}
          borderRadius={"5px"}
          backdropFilter="auto"
          backdropBlur="2px"
          boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
          // width={{ base: "160px", md: "180px", lg: "300px" }}
          height={{ base: "300px", md: "320px", lg: "420px" }}
        >
          <Flex
            flexDir={"column"}
            mt={"20px"}
            width={"100%"}
            justifyContent="center"
            alignItems="center"
          >
            <Image
            alt="Blockchain Trackgenesis"
              src={Blk}
              className="floatinganime"
              width={{ base: "35%", md: "35%", lg: "40%" }}
            ></Image>
            <div className="shadowanime"></div>
            <Heading
              textAlign={"center"}
              mt="10px"
              px={{ base: 3, md: 3, lg: 9 }}
              fontSize={{ base: "12px", md: "12px", lg: "17px" }}
              color={"white"}
              className="content_font"
              
           as={"h1"}
            >
              Blockchain Solutions
            </Heading>
            <Text
               px={{ base: "20px", md: "20px", lg: "50px" }}
              mt="30px"
              fontSize={{ base: "12px", md: "12px", lg: "15px" }}
              color={"white"}
              className="content_font"
              fontWeight={"light"}
              noOfLines={4}
             
              maxHeight={{ base: "87px", md: "100px", lg: "110px" }}
            >
              Blockchain builds a transparent and trust-worthy business and
              prove your sustainability claims of your consumer good products.
              By adopting promising blockchain technology, your business can
              stand out among the competitors and win new customers as well as
              investors.
            </Text>
            <Flex
              mx={"auto"}
              mt={"20px"}  borderRadius={"4px"}
              padding={"2px"}
              width={"fit-content"}
              height={"fit-content"}
              border="1px solid #01B0D7"
            >
              <Button
                borderRadius={"2px"}
                onClick={onFirstModalOpen}
                width={{ base: "68px", md: "80px", lg: "90px" }}
                height={{ base: "20px", md: "20px", lg: "28px" }}
                className="content_font"
                backgroundColor={"#01B0D7"}
                _hover={{ bg: "#8DE6F9" }}
                fontSize={{
                  base: "12px",
                  md: "13px",
                  lg: "14px",
                }}
              >
                Read more
              </Button>
            </Flex>
          </Flex>
        </Box>
        <Modal isOpen={isFirstModalOpen} onClose={onFirstModalClose}>
          <ModalOverlay />
          <ModalContent backgroundColor={"#0B091E"}>
            <ModalHeader>
              <Flex
                width={"100%"}
                flexDirection="column"
                justifyContent={"center"}
                alignItems="center"
              >
                <Heading
                  textAlign={"center"}
                  px={{ base: 3, md: 3, lg: 9 }}
                  fontSize={{ base: "17px", md: "18px", lg: "22px" }}
                  as={"h3"}
                  fontWeight="normal"
                  color={"white"}
                  className="content_font"
                >
                  Blockchain Solutions
                </Heading>
                <Image
                  alt="Blockchain Trackgenesis"
                  mt="30px"
                  src={Blk}
                  width={{ base: "28%", md: "30%", lg: "29%" }}
                ></Image>
              </Flex>
            </ModalHeader>
            <ModalCloseButton color={"white"} />
            <ModalBody>
              <Text
                p={{ base: 3, md: 3, lg: 5 }}
                fontSize={{ base: "16px", md: "16px", lg: "17px" }}
                mt="10px"
                color={"white"}
                className="content_font"
                fontWeight={"light"}
              >
                {" "}
                Blockchain builds a transparent and trust-worthy business and
                prove your sustainability claims of your consumer good products.
                By adopting promising blockchain technology, your business can
                stand out among the competitors and win new customers as well as
                investors.
              </Text>
            </ModalBody>

            <ModalFooter>
              <CommonButton onClick={onFirstModalClose} btn="Close" />
            </ModalFooter>
          </ModalContent>
        </Modal>
        {/* Customized NFT Applications */}
        {/* <Box
          mt={{ base: "-40px", md: "-40px", lg: "-60px" }}
          _hover={{
            backgroundColor: "rgba(0, 0, 0, 0.3)", // Set the background color on hover
          }}
          cursor={"pointer"}
          overflow={"hidden"}
          backgroundColor={"rgba(0, 0, 0, 0.15)"}
          borderRadius={"5px"}
          backdropFilter="auto"
          backdropBlur="2px"
          boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
          // width={{ base: "160px", md: "180px", lg: "300px" }}
          height={{ base: "380px", md: "380px", lg: "420px" }}
        >
          <Flex
            flexDir={"column"}
            mt={"20px"}
            width={"100%"}
            justifyContent="center"
            alignItems="center"
          >
            <Image
              alt="NFT Trackgenesis"
              src={NFT}
              className="floatinganime"
              width={{ base: "40%", md: "40%", lg: "45%" }}
            ></Image>
            <div className="shadowanime"></div>
            <Heading
              textAlign={"center"}
              mt="-10px"
              px={{ base: 3, md: 3, lg: 9 }}
              fontSize={{ base: "12px", md: "12px", lg: "17px" }}
              color={"white"}
              className="content_font"
              as={"h3"}
             
            >
              NFT Applications
            </Heading>
            <Text
              p={{ base: 3, md: 3, lg: 5 }}
              mt="10px"
              fontSize={{ base: "12px", md: "12px", lg: "15px" }}
              color={"white"}
              className="content_font"
              fontWeight={"light"}
              noOfLines={4}
              w={{ base: "140px", md: "150px", lg: "300px" }}
              maxHeight={{ base: "87px", md: "100px", lg: "110px" }}
            >
              From assigning ownership of digital assets to, possibilities of
              NFTs in business are endless. Non-fungible tokens create
              exclusive, unique experience for customers and elevate your brand
              to new heights.
            </Text>
            <Flex
              mx={"auto"}
              mt={"20px"}
              borderRadius={"4px"}
              padding={"2px"}
              width={"fit-content"}
              height={"fit-content"}
              border="1px solid #01B0D7"
            >
              <Button
                borderRadius={"2px"}
                onClick={onSecondModalOpen}
                width={{ base: "68px", md: "80px", lg: "90px" }}
                height={{ base: "20px", md: "20px", lg: "28px" }}
                className="content_font"
                backgroundColor={"#01B0D7"}
                _hover={{ bg: "#8DE6F9" }}
                fontSize={{
                  base: "12px",
                  md: "13px",
                  lg: "14px",
                }}
              >
                Read more
              </Button>
            </Flex>
          </Flex>
        </Box>
        <Modal isOpen={isSecondModalOpen} onClose={onSecondModalClose}>
          <ModalOverlay />
          <ModalContent backgroundColor={"#0B091E"}>
            <ModalHeader>
              <Flex
                width={"100%"}
                flexDirection="column"
                justifyContent={"center"}
                alignItems="center"
              >
                <Heading
                  textAlign={"center"}
                  px={{ base: 3, md: 3, lg: 9 }}
                  fontSize={{ base: "17px", md: "18px", lg: "22px" }}
                  as={"h3"}
                  fontWeight="normal"
                  color={"white"}
                  className="content_font"
                >
                  NFT Applications
                </Heading>
                <Image
                  alt="NFT Trackgenesis"
                  mt="30px"
                  src={NFT}
                  width={{ base: "28%", md: "30%", lg: "39%" }}
                ></Image>
              </Flex>
            </ModalHeader>
            <ModalCloseButton color={"white"} />
            <ModalBody>
              <Text
                p={{ base: 3, md: 3, lg: 5 }}
                fontSize={{ base: "16px", md: "16px", lg: "17px" }}
                mt="10px"
                className="content_font"
                color={"white"}
                fontWeight={"light"}
              >
                {" "}
                From assigning ownership of digital assets to, possibilities of
                NFTs in business are endless. Non-fungible tokens create
                exclusive, unique experience for customers and elevate your
                brand to new heights.
              </Text>
            </ModalBody>

            <ModalFooter>
              <CommonButton onClick={onSecondModalClose} btn="Close" />
            </ModalFooter>
          </ModalContent>
        </Modal> */}
    
        {/* Bespoke Software Solutions */}
        <Box
          mt={{ base: "-40px", md: "-40px", lg: "-60px" }}
          _hover={{
            backgroundColor: "rgba(0, 0, 0, 0.3)", // Set the background color on hover
          }}
          cursor={"pointer"}
          overflow={"hidden"}
          backgroundColor={"rgba(0, 0, 0, 0.15)"}
          borderRadius={"5px"}
          backdropFilter="auto"
          backdropBlur="2px"
          boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
          // width={{ base: "160px", md: "180px", lg: "300px" }}
          height={{ base: "300px", md: "320px", lg: "420px" }}
        >
          <Flex
            flexDir={"column"}
            mt={"20px"}
            width={"100%"}
            justifyContent="center"
            alignItems="center"
          >
            <Image
              alt="Software Solutions Trackgenesis"
              className="floatinganime"
              src={Soft}
              width={{ base: "38%", md: "38%", lg: "40%" }}
            ></Image>
            <div className="shadowanime"></div>
            <Heading
              textAlign={"center"}
              mt="-10px"
              px={{ base: 3, md: 3, lg: 9 }}
              fontSize={{ base: "12px", md: "12px", lg: "17px" }}
              color={"white"}
              className="content_font"
              as={"h3"}
             
            >
              Bespoke Software Solutions
            </Heading>
            <Text
          
             
              fontSize={{ base: "12px", md: "12px", lg: "15px" }}
              color={"white"}
              className="content_font"
              fontWeight={"light"}
              noOfLines={4}
                px={{ base: "20px", md: "20px", lg: "50px" }}
              mt="30px"
              maxHeight={{ base: "87px", md: "100px", lg: "110px" }}
            >
              TrackGenesis develop custom software solutions that fit your
              organization. We have specialists covering everything from basic
              web development to cutting-edge technologies like Blockchain, AR,
              VR, and Artificial Intelligence. We have a client-focused mindset,
              and our approach is to deep dive and understand your business and
              its requirements.
            </Text>
            <Flex
              mx={"auto"}
              mt={"20px"}
              borderRadius={"4px"}
              padding={"2px"}
              width={"fit-content"}
              height={"fit-content"}
              border="1px solid #01B0D7"
            >
              <Button
               borderRadius={"2px"}
                onClick={onForthModalOpen}
                width={{ base: "68px", md: "80px", lg: "90px" }}
                height={{ base: "20px", md: "20px", lg: "28px" }}
                className="content_font"
                backgroundColor={"#01B0D7"}
                _hover={{ bg: "#8DE6F9" }}
                fontSize={{
                  base: "12px",
                  md: "13px",
                  lg: "14px",
                }}
              >
                Read more
              </Button>
            </Flex>
          </Flex>
        </Box>
        <Modal isOpen={isForthModalOpen} onClose={onForthModalClose}>
          <ModalOverlay />
          <ModalContent backgroundColor={"#0B091E"}>
            <ModalHeader>
              <Flex
                width={"100%"}
                flexDirection="column"
                justifyContent={"center"}
                alignItems="center"
              >
                <Heading
                  textAlign={"center"}
                  px={{ base: 3, md: 3, lg: 9 }}
                  fontSize={{ base: "17px", md: "18px", lg: "22px" }}
                  as={"h3"}
                  fontWeight="normal"
                  color={"white"}
                  className="content_font"
                >
                  {" "}
                  Bespoke Software Solutions
                </Heading>
                <Image
                alt="Software Solutions Trackgenesis"
                  mt="30px"
                  src={Soft}
                  width={{ base: "28%", md: "30%", lg: "43%" }}
                ></Image>
              </Flex>
            </ModalHeader>
            <ModalCloseButton color={"white"} />
            <ModalBody>
              <Text
                p={{ base: 3, md: 3, lg: 5 }}
                fontSize={{ base: "16px", md: "16px", lg: "17px" }}
                mt="10px"
                color={"white"}
                className="content_font"
                fontWeight={"light"}
              >
                {" "}
                TrackGenesis develop custom software solutions that fit your
                organization. We have specialists covering everything from basic
                web development to cutting-edge technologies like Blockchain,
                AR, VR, and Artificial Intelligence. We have a client-focused
                mindset, and our approach is to deep dive and understand your
                business and its requirements. Followed by analysing these
                requirements to find out suitable technology solutions that can
                support and fit your business needs. While we are committed to
                our employees and customers, we have a strong vision for Net
                Zero Carbon as well. We strongly believe in contributing to the
                environment by using environmentally friendly technologies. Our
                blockchain technology is guaranteed to be environmentally
                friendly to support the net-zero initiative.
              </Text>
            </ModalBody>

            <ModalFooter>
              <CommonButton onClick={onForthModalClose} btn="Close" />
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Box display={{base:"block",md:"none",lg:"none"}}></Box>
            {/* Augmented and Virtual Reality (AR & VR) Apps */}
            <Box
              mt={{ base: "-120px", md: "0px", lg: "0px" }}
          _hover={{
            backgroundColor: "rgba(0, 0, 0, 0.3)", // Set the background color on hover
          }}
          cursor={"pointer"}
          overflow={"hidden"}
          backgroundColor={"rgba(0, 0, 0, 0.15)"}
          borderRadius={"5px"}
          backdropFilter="auto"
          backdropBlur="2px"
          boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
          // width={{ base: "160px", md: "180px", lg: "300px" }}
          height={{ base: "300px", md: "320px", lg: "420px" }}
        >
          <Flex
            flexDir={"column"}
            mt={"20px"}
            width={"100%"}
            justifyContent="center"
            alignItems="center"
          >
            <Image
              alt="ARVR Trackgenesis"
              className="floatinganime"
              src={ARVR}
              width={{ base: "50%", md: "50%", lg: "39%" }}
            ></Image>
            <div className="shadowanime"></div>
            <Heading
              textAlign={"center"}
              mt="-10px"
              px={{ base: 3, md: 3, lg: 9 }}
              fontSize={{ base: "12px", md: "12px", lg: "17px" }}
              color={"white"}
              className="content_font"
              as={"h3"}
            
            >
              Augmented and Virtual Reality (AR & VR) Apps
            </Heading>
            <Text
              px={{ base: "20px", md: "20px", lg: "50px" }}
              mt="30px"
              fontSize={{ base: "12px", md: "12px", lg: "15px" }}
              color={"white"}
              className="content_font"
              fontWeight={"light"}
              noOfLines={4}
              
              maxHeight={{ base: "87px", md: "100px", lg: "110px" }}
            >
              AR/VR apps makes a more engaging user experience. We created apps
              for companies in the food and beverage sector, and when a customer
              scans the product label, it will show a lovely animation with
              three-dimensional models that tells the story of the product. This
              will make our customers' brands more innovative and advanced,
              which is essential for companies who sell premium goods.
            </Text>
            <Flex
              mx={"auto"}
              mt={"20px"}
              borderRadius={"4px"}
              padding={"2px"}
              width={"fit-content"}
              height={"fit-content"}
              border="1px solid #01B0D7"
            >
              <Button
                borderRadius={"2px"}
                onClick={onThirdModalOpen}
                width={{ base: "68px", md: "80px", lg: "90px" }}
                height={{ base: "20px", md: "20px", lg: "28px" }}
                className="content_font"
                backgroundColor={"#01B0D7"}
                _hover={{ bg: "#8DE6F9" }}
                fontSize={{
                  base: "12px",
                  md: "13px",
                  lg: "14px",
                }}
              >
                Read more
              </Button>
            </Flex>
          </Flex>
        </Box>
        <Modal isOpen={isThirdModalOpen} onClose={onThirdModalClose}>
          <ModalOverlay />
          <ModalContent backgroundColor={"#0B091E"}>
            <ModalHeader>
              <Flex
                width={"100%"}
                flexDirection="column"
                justifyContent={"center"}
                alignItems="center"
              >
                <Heading
                  textAlign={"center"}
                  px={{ base: 3, md: 3, lg: 9 }}
                  fontSize={{ base: "17px", md: "18px", lg: "22px" }}
                  as={"h3"}
                  fontWeight="normal"
                  color={"white"}
                  className="content_font"
                >
                  Augmented and Virtual Reality (AR & VR) Apps
                </Heading>
                <Image
                  alt="ARVR Trackgenesis"
                  mt="30px"
                  src={ARVR}
                  width={{ base: "40%", md: "40%", lg: "42%" }}
                ></Image>
              </Flex>
            </ModalHeader>
            <ModalCloseButton color={"white"} />
            <ModalBody>
              <Text
                p={{ base: 3, md: 3, lg: 5 }}
                fontSize={{ base: "16px", md: "16px", lg: "17px" }}
                mt="10px"
                className="content_font"
                color={"white"}
                fontWeight={"light"}
              >
                {" "}
                AR/VR apps makes a more engaging user experience. We created
                apps for companies in the food and beverage sector, and when a
                customer scans the product label, it will show a lovely
                animation with three-dimensional models that tells the story of
                the product. This will make our customers' brands more
                innovative and advanced, which is essential for companies who
                sell premium goods. AR/VR is also a game changer in fashion
                industry, it will enable customers to visualise how a product
                will look on them before making a purchase. With the use of AR
                and VR, we can visualise how a furniture will look inside our
                home without having to physically place it there. We've just
                listed a few examples here, but AR and VR have applications in
                many other industries.
              </Text>
            </ModalBody>

            <ModalFooter>
              <CommonButton onClick={onThirdModalClose} btn="Close" />
            </ModalFooter>
          </ModalContent>
        </Modal>
      </SimpleGrid>
    </Flex>
  );
}

export default WhatWeDo;
