import { Flex, Center, Text, Image, VStack, Button, Divider } from "@chakra-ui/react";
import { FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react";
import React, { useState } from "react";
import Footer from "../../components/sections/Footer";
import Navbar from "../../components/sections/Navbar";
import Mobilenavbar from "../../components/sections/Mobilenavbar";
import { useForm as useFormspree } from "@formspree/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import contactRobo from "../../assets/images/ContactUsAndCareer/contact-image.webp";

import Contactusheader from "../../assets/images/ContactUsAndCareer/ContactusHeader.webp";
import ParticleBackground from "../../components/sections/home/ParticleBackground";
import ParticleTrail from "../../components/sections/home/ParticleTrail";

function Contactus(props) {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [query, setQuery] = useState();
  const navigate = useNavigate();

  // react form hook
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // formspree

  const [serverState, sendToFormspree] = useFormspree("xdobjege");

  // on succesfull submission of form in formspree
  if (serverState.succeeded) {
    navigate("/home");
  }

  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>Contact Info | TrackGenesis® | Aberdeen Scotland</title>
        <meta
          name="title"
          content="Contact Blockchain, NFT, AR/VR & Software Development Company | TrackGenesis®️ | Scotland UK"
        />
        <meta
          name="description"
          content="Reach out us for technology partnership. To know more about our products and services, send us your queries at info@trackgenesis.com."
        />
        <link
          href="https://trackgenesis.com/contact-us"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />
    
              {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=UA-197747980-1"
    ></script>
      </Helmet>
      {/* helmet */}

      <Flex flexDirection={"column"} backgroundColor={"black"} id="contact">
        {/* new heading section */}
        <Flex>
          <Image
           ml={{base:"-50px",md:"-40px",lg:"0px"}}
            width={{ base: "270px", md: "400px", lg: "500px" }}
            src={Contactusheader}
            alt="Contact Blockchain, NFT, AR/VR & Software Development Company"
          ></Image>
          <Text
            mt={"auto"}
            className="heading"
          
            mb={{base:"15px",md:"15px",lg:"30px"}}
            color={"#94DAF8"}
            fontSize={{ base: "30px", md: "45px", lg: "50px" }}
            ml={{ base: "-29%", md: "-19%", lg: "-10%" }}
          >
            Contact Us
          </Text>
        </Flex>

      
        <Navbar />
        <Mobilenavbar />

       
      <ParticleTrail/>
        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
        
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="4px"
        >
          <Flex
  
            my={"90px"}
            mx={"auto"}
            width={{ base: "80%", md: "80%", lg: "80%" }}
            backgroundColor={"rgba(0, 0, 0, 0.25)"}
            borderRadius={"5px"}
            backdropFilter="auto"
            backdropBlur="2px"
            boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
          >
            {/* content */}
            <Flex
            
            mx={"auto"}
              width={{ base: "100%", md: "100%", lg: "100%" }}
              // position={"absolute"}
              className="animatedborderteam"
           
             
              borderRadius="20px"
              boxShadow={"lg"}
              flexDirection={{ base: "column", md: "row", lg: "row" }}
            >
              <Flex
            
                width={{ base: "100%", md: "50%", lg: "50%" }}
                justifyContent="center"
                alignItems={"center"}
              >
                <Image
                 className="animate__animated animate__slow animate__fadeIn "
                objectFit={"contain"}
                alt="Contact Blockchain, NFT, AR/VR & Software Development Company"
                  p={{ base: "20px", md: "10px", lg: "70px" }}
                  width={{ base: "250px", md: "500px", lg: "500px" }}
                  src={contactRobo}
                  pointerEvents={"none"}
                ></Image>
                <Divider
            borderColor="#01B0D7"
            border={"2px solid"}
            display={{ base: "none", md: "none", lg: "flex" }}
            boxShadow="3px 0px 22.7px 0px #01B1D8"
            maxH={"500px"}
            my={"auto"}
            mx={"60px"}
            orientation="vertical"
          />
              </Flex>

              <Flex
              
               
                width={{ base: "100%", md: "50%", lg: "50%" }}
                justifyContent="center"
                flexDirection={"column"}
                fontSize={{ base: "14px", md: "15px", lg: "16px" }}
                className="content_font"
              >
                <Flex width={"100%"}  mx={"auto"}   p={{base:"20px",md:"20px",lg:"30px"}} flexDirection={"column"}>
                <Text
                  // data-aos="fade-in"
                  // data-aos-easing="ease-in-quad"
                  // data-aos-duration="3000"
                  className="heading"
                  color={"#94DAF8"}
                  fontSize={{ base: "23px", md: "25px", lg: "25px" }}
                  pt={9}
                >
                  Speak to our experts
                </Text>
                <Text
                  mt={5}
                  width={{ base: "200px", md: "100%", lg: "100%" }}
                  // data-aos="fade-in"
                  // data-aos-easing="ease-in-quad"
                  // data-aos-duration="3000"
                  fontSize={{ base: "14px", md: "14px", lg: "16px" }}
                  color={"white"}
                  className="content_font"
                  fontWeight={"light"}
                >
                  Send us your query and we will get back to you soon
                </Text>

                <form onSubmit={handleSubmit(sendToFormspree)}>
                  <VStack
                    width={{ base: "90%", md: "100%", lg: "100%" }}
                    pt={"40px"}
                    pb="40px"
                  >
                    <FormControl>
                      <FormLabel
                      fontSize={{ base: "12px", md: "12px", lg: "15px" }}
                        color={"white"}
                        className="content_font"
                        fontWeight={"light"}
                      >
                        Name
                      </FormLabel>
                      <Input
                       border={"1px solid "}
                       borderColor={"#94DAF8"}
                        color={"white"}
                        className="content_font"
                        fontWeight={"light"}
                        boxShadow={"inner"}
                        bgColor={"#0B091E"}
                        id="name"
                        type="text"
                        value={name}
                        name="name"
                        {...register("name", {
                          required: "Name field is required",
                        })}
                      />
                      <Text className="error">{errors?.name?.message}</Text>
                    </FormControl>

                    <FormControl>
                      <FormLabel
                      mt={"20px"}
                      fontSize={{ base: "12px", md: "12px", lg: "15px" }}
                        color={"white"}
                        className="content_font"
                        fontWeight={"light"}
                      >
                        Email
                      </FormLabel>
                      <Input
                      border={"1px solid "}
                      borderColor={"#94DAF8"}
                        color={"white"}
                        className="content_font"
                        fontWeight={"light"}
                        boxShadow={"inner"}
                        bgColor={"#0B091E"}
                        id="email"
                        value={email}
                        name="email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                            message: "Invalid email format",
                          },
                        })}
                      />
                      <Text className="error">{errors?.email?.message}</Text>
                    </FormControl>

                    <FormControl>
                      <FormLabel
                      mt={"20px"}
                      fontSize={{ base: "12px", md: "12px", lg: "15px" }}
                        color={"white"}
                        className="content_font"
                        fontWeight={"light"}
                      >
                        Contact Number
                      </FormLabel>
                      <Input
                      border={"1px solid "}
                      borderColor={"#94DAF8"}
                        color={"white"}
                        className="content_font"
                        fontWeight={"light"}
                        boxShadow={"inner"}
                        bgColor={"#0B091E"}
                        id="number"
                        value={number}
                        name="number"
                        {...register("number", {
                          required: "Number is required",
                          pattern: {
                            value: /^(\+?[\d] *){10,15}$/,
                            message: "Invalid number",
                          },
                         
                        })}
                      />
                      <Text className="error">{errors?.number?.message}</Text>
                    </FormControl>

                    <FormControl>
                      <FormLabel
                         mt={"20px"}
                        color={"white"}
                        className="content_font"
                        fontWeight={"light"}
                        fontSize={{ base: "12px", md: "12px", lg: "15px" }}
                      >
                        Enter your queries
                      </FormLabel>
                      <Textarea
                        color={"white"}
                        className="content_font"
                        fontWeight={"light"}
                        boxShadow={"inner"}
                        bgColor={"#0B091E"}
                        border={"1px solid "}
                        borderColor={"#94DAF8"}
                        id="query"
                        value={query}
                        name="query"
                        {...register("query", {
                          required: "Query is required",
                          minLength: {
                            value: 10,
                            message: "Query must have at least 10 characters",
                          },
                          maxLength: {
                            value: 500,
                            message: "Query must not exceed 500 characters",
                          },
                        })}
                      />
                      <Text className="error">{errors?.query?.message}</Text>
                    </FormControl>

                    <Flex width={"100%"} justifyContent="end" pt={6}>
                      <Flex
                        borderRadius={"6px"}
                        padding={"2px"}
                        width={"fit-content"}
                        height={"fit-content"}
                        border="1px solid #01B0D7"
                      >
                        <Button
                          width={{ base: "68px", md: "80px", lg: "90px" }}
                          height={{ base: "16px", md: "20px", lg: "30px" }}
                          className="heading"
                          backgroundColor={"#01B0D7"}
                          _hover={{ bg: "#8DE6F9" }}
                          fontSize={{
                            base: "12px",
                            md: "13px",
                            lg: "15px",
                          
                          }}
                          type="submit"
                          disabled={serverState.submitting}
                        >
                          Submit
                        </Button>
                      </Flex>
                    </Flex>
                  </VStack>
                  {serverState.errors.map((err) => (
                    <Text>{err.message}</Text>
                  ))}
                </form></Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <ParticleBackground />
        <Footer />
      </Flex>
    </>
  );
}

export default Contactus;
