import React from "react";
import AboutusSection from "./AboutusSection";
import Footer from "../Footer";
import HomeTestimonialsSection from "./HomeTestimonialsSection";
import HomeServiceSection from "./HomeServiceSection.js";
import LandingSection from "./LandingSection";
import { Flex,Image } from "@chakra-ui/react";
import HomeClientSection from "./HomeClientSection.js";
import ParticleBackground from "./ParticleBackground.js";
import ParticleTrail from "./ParticleTrail.js";



function Home() {
  return (
    <Flex  flexDir={"column"} backgroundColor={"black"}>
      <LandingSection />
     
      <ParticleTrail/>
      <Flex
        flexDir={"column"}
        position={"relative"}
        className="mainborder"
        my={{ base: "2%", md: "2%", lg: "2%" }}
        mx={{base:"10px",md:"15px",lg:"30px"}}
        backgroundColor={"rgba(27, 21, 78, 0.38)"}
        backdropFilter="auto"
        backdropBlur="4px"
      >
    
      
        <AboutusSection />
        <HomeServiceSection />
        <HomeClientSection/>
        {/* <HomeTestimonialsSection /> */}
      
      </Flex>
    
      <ParticleBackground />
     
      <Footer />
    </Flex>
  );
}

export default Home;
