import { Button ,Image, Flex} from "@chakra-ui/react";
import React from "react";

function CommonButton(props) {
  const {
    isLoading,
    bg = "#01B0D7",
    hoverBg = "#006A80",
    color = "#0B091E",
    type,
    alignSelf,
    border,
    width = "85px",
    height = "32px",
    p,
    fontSize = "13px",
    btn,
    icon,
    focusBg,
    activeBg,
    borderRadius = "4px",
    boxShadow = " 0px 0px 4.3px 0px #01B1D8",
    onClick,
    mt,
    ml,mb,
    ...rest
  } = props;
  return (
    <Flex borderRadius={"4px"} ml={ml} mb={mb} mt={mt} padding={"2px"} width={"fit-content"} height={"fit-content"} border="1px solid #01B0D7">
    <Button
   className="content_font"
      p={p}
      height={height}
      isLoading={isLoading}
      bg={bg}
      _hover={{ bg: "#8DE6F9" }}
      color={color}
      type={type}
      alignSelf={alignSelf}
      border={border}
      width={width}
      fontSize={fontSize}
      _focus={{ bg: focusBg }}
      _active={{ bg: activeBg }}
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      onClick={onClick}
    
      {...rest}
    >
      {btn} 
    
    </Button></Flex>
  );
}

export default CommonButton;
