import React, { useState } from "react";
import { Flex, Text, Image, Button, Box, Divider } from "@chakra-ui/react";
import { VscMute, VscUnmute } from "react-icons/vsc";

import trackgenesisVideo from "../../../assets/images/AboutUsPage/trackgenesis-video.mp4";
import Underline from "../../../assets/images/HomePage/underline.webp";
import CommonButton from "../../CommonButton";
import { HashLink } from "react-router-hash-link";

function AboutusSection(props) {
  const [openIndex, setOpenIndex] = useState(null);
  const [mute, setMute] = useState(true);
  const handleBoxClick = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };
  return (
  
    <Flex
      p={{ base: "20px" }}
      height={{ base: "fit-content", md: "100vh", lg: "100vh" }}
      width={"100%"}
    >
      <Flex
        width={"100%"}
        my={"auto"}
        flexDir={{ base: "column-reverse", md: "column-reverse", lg: "row" }}
      >
        {/* Video Flex */}
        <Flex
          mt={{ base: "40px", md: "40px", lg: "0px" }}
          p={3}
          width={{ base: "100%", md: "100%", lg: "50%" }}
        >
          <Flex
            mx={"auto"}
            my={"auto"}
            p={3}
            border={"1px solid #01B1D8"}
            borderRadius={"10px"}
            width={{ base: "", md: "500px", lg: "650px" }}
            boxShadow="0px 0px 22.7px 0px #01B1D8"
          >
            <div>
              <video
                playsInline
                className="videobox"
                src={trackgenesisVideo}
                style={{ borderRadius: "10px" }}
                autoPlay={true}
                muted={mute}
                controls={false}
                loop
              />
            </div>
            <Box
              position={"absolute"}
              p={2}
              height={"fit-content"}
              width={"fit-content"}
              backgroundColor={"rgba(0,0,0,0.5)"}
              color={"white"}
              boxShadow={"lg"}
              borderRadius={"50%"}
            >
              {mute ? (
                <VscMute
                  cursor={"pointer"}
                  size={"20"}
                  onClick={() => {
                    setMute(!mute);
                  }}
                />
              ) : (
                <VscUnmute
                  cursor={"pointer"}
                  size="20"
                  onClick={() => {
                    setMute(!mute);
                  }}
                />
              )}
            </Box>
          </Flex>
          <Divider
            borderColor="#01B0D7"
            border={"2px solid"}
            display={{ base: "none", md: "none", lg: "flex" }}
            boxShadow="3px 0px 22.7px 0px #01B1D8"
            maxH={"500px"}
            my={"auto"}
            ml={"60px"}
            orientation="vertical"
          />
        </Flex>

        {/* Content Flex */}
        <Flex width={{ base: "100%", md: "100%", lg: "50%" }}>
          <Flex flexDir={"column"} mx={"auto"} my={"auto"}>
            <Flex
              flexDir={"column"}
              // ml={{base:"20px",md:"20px",lg:"-5px"}}
            >
              <Text
                className="heading"
                color={"#94DAF8"}
                fontSize={{ base: "24px", md: "38px", lg: "45px" }}
              >
                About Us
              </Text>
              <Image
                alt="underline"
                mt={"-10px"}
                ml={"-10px"}
                width={"70px"}
                src={Underline}  
              ></Image>
            </Flex>
            <Flex flexDir={"column"}
            style={{
              height: 4 === openIndex ? "390px" : "220px",
              transition: "height 0.4s linear",
            }}
            >
            <Text
              mx={"auto"}
              mt={"40px"}
              fontSize={{ base: "12px", md: "14px", lg: "15.2px" }}
              maxW={{ base: "400px", md: "400px", lg: "450px" }}
              color={"white"}
              className="content_font"
         
             
            >
              TrackGenesis is a leading software company and consulting firm
              headquartered in Aberdeen, Scotland. Our experts craft bespoke
              solutions, seamlessly merging cutting-edge technology with your
              business goals. We offer custom software solutions, harness the
              power of blockchain technology that support sustainable business
              for secure and transparent transactions, create AR/VR experiences
              to transform user experiences, and explore the future of digital
              ownership with our expertise in NFTs. 
              {/* hide */}
              </Text>
              <Text
               mx={"auto"}
               mt={"40px"}
               fontSize={{ base: "12px", md: "14px", lg: "15px" }}
               maxW={{ base: "400px", md: "400px", lg: "450px" }}
               color={"white"}
               className="content_font"
               fontWeight={"light"}
            style={{
              opacity: 4 === openIndex ? 1 : 0,
              transition: "opacity 0.1s linear",
            }}
              >
              As a technology partner, we
              empower business to thrive in the digital landscape with
              unrivalled creativity and technical brilliance. While we are
              committed to our employees and customers, we have a strong vision
              for net zero carbon as well. We strongly believe in contributing
              to the environment by using environmentally friendly technologies.
            </Text>
            </Flex>
              <CommonButton
               onClick={() => handleBoxClick(4)}
              
                // ml={{base:"20px",md:"20px",lg:"-0px"}}
                width={{ base: "80px", md: "80px", lg: "100px" }}
                height={{ base: "25px", md: "30px", lg: "40px" }}
                mt={{ base: "20px", md: "10px", lg: "40px" }}
                btn={4 === openIndex ? "Read less": "Read more"}
              />
          
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default AboutusSection;
