import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";

import CommonButton from "../../../components/CommonButton.js";
import { VscMute, VscUnmute } from "react-icons/vsc";
import BookVideo from "../../../assets/images/ProductsPage/book.mp4";
import Underline from "../../../assets/images/HomePage/underline.webp";
function Book() {
  const [mute, setMute] = useState(true);
  return (
    // new code
    <Flex
      id="arbook"
      mt={{ base: "40px", md: "80px", lg: "200px" }}
      flexDir={"column"}
      width={"100%"}
    >
      {/* title */}
      <Flex flexDir={"column"} ml={{ base: "6%", md: "6%", lg: "6%" }}>
        <Text
          className="heading"
          color={"#94DAF8"}
          fontSize={{ base: "24px", md: "38px", lg: "45px" }}
        >
          Book of Wonders (AR Book)
        </Text>
        <Image mt={"-10px"} ml={"-10px"} width={"70px"} src={Underline}  alt="underline"></Image>
      </Flex>
      {/* content */}
      <Flex
        flexDir={{ base: "column", md: "column", lg: "row" }}
        mx={"auto"}
        width={{ base: "100%", md: "90%", lg: "80%" }}
        mt={{ base: "10px", md: "40px", lg: "70px" }}
      >
        <Flex
          px={{ base: "20px", md: "30px", lg: "20px" }}
          mt={{ base: "40px", md: "40px", lg: "0px" }}
          width={{ base: "100%", md: "100%", lg: "50%" }}
        >
          <Flex
            mx={"auto"}
            my={"auto"}
            borderRadius={"10px"}
            width={{ base: "400px", md: "500px", lg: "650px" }}
          >
            <Flex
              boxShadow="0px 0px 17.7px 0px #49B9C1"
              mt={{ base: "-10px", md: "-10px", lg: "-10px" }}
              ml={{ base: "-10px", md: "-10px", lg: "-10px" }}
              borderRadius={"10px"}
              position={"absolute"}
              zIndex={-1}
              border={"1px solid #49B9C1"}
              width={{ base: "200px", md: "300px", lg: "300px" }}
              height={{ base: "100px", md: "100px", lg: "150px" }}
            ></Flex>
            <Flex borderRadius={"11px"} border={"1px solid #49B9C1"}>
              <div>
                <video
                 alt="Augmented reality book for fun and interactive learning for kids"
                  playsInline
                  className="videobox"
                  src={BookVideo}
                  style={{ borderRadius: "10px", objectFit: "fill" }}
                  autoPlay={true}
                  muted={mute}
                  controls={false}
                  loop
                />
              </div>
            </Flex>

            <Box
              position={"absolute"}
              p={2}
              height={"fit-content"}
              width={"fit-content"}
              backgroundColor={"rgba(0,0,0,0.5)"}
              color={"white"}
              boxShadow={"lg"}
              borderRadius={"50%"}
            >
              {mute ? (
                <VscMute
                  cursor={"pointer"}
                  size={"20"}
                  onClick={() => {
                    setMute(!mute);
                  }}
                />
              ) : (
                <VscUnmute
                  cursor={"pointer"}
                  size="20"
                  onClick={() => {
                    setMute(!mute);
                  }}
                />
              )}
            </Box>
          </Flex>
        </Flex>
        <Flex
          ml={{ base: "0px", md: "0px", lg: "20px" }}
          p={{ base: "10px", md: "10px", lg: "30px" }}
          mt={{ base: "40px", md: "40px", lg: "0px" }}
          width={{ base: "100%", md: "100%", lg: "50%" }}
        >
          <Flex
            width={"100%"}
            px={"40px"}
            height={"fit-content"}
            mx={"auto"}
            my={"auto"}
            flexDir={"column"}
          >
            {" "}
            <Text
              width={{ base: "100%", md: "100%", lg: "100%" }}
              fontSize={{ base: "14px", md: "14px", lg: "16px" }}
              color={"white"}
              className="content_font"
              fontWeight={"light"}
            >
              Augmented Reality (AR) book made specifically for curious young
              brains. This is an ideal gift for your child to encourage fun and
              interactive learning. The book sparks their interest and keeps
              them engaged for an extended period of time. Kids who are learning
              new lessons and concepts like the Solar System, Insects, Birds,
              Wild Animals, and Domestic Animals are amazed to see them live
              before their eyes, and this experience stays with them. You may
              download our AR app from Google Play or the App Store and scan the
              book's pages to see them come to life. Book of Wonders provides an
              unforgettable reading experience that makes your child eagerly
              anticipating the next chapter. Talk to our experts & you can do
              wonders with AR!
            </Text>
            <HashLink smooth to={"/contact-us#contact"}>
              <CommonButton
              width='100px'
                btn="Book a Demo"
                mt={{ base: "30px", md: "40px", lg: "30px" }}
              />
            </HashLink>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Book;
