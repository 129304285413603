import { Box, Center, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import Footer from "../../components/sections/Footer";
import Navbar from "../../components/sections/Navbar";
import Mobilenavbar from "../../components/sections/Mobilenavbar";
import WhatWeDo from "../../components/sections/aboutUs/WhatWeDo";
import OurTeam from "../../components/sections/aboutUs/OurTeam";

import Aboutusheader from "../../assets/images/AboutUsPage/aboutusheader.webp";
import Teambg from "../../assets/images/AboutUsPage/teambg.webp";
import Visionnew from "../../assets/images/AboutUsPage/vision.webp";
import { Helmet } from "react-helmet";
import ParticleBackground from "../../components/sections/home/ParticleBackground";
import ParticleTrail from "../../components/sections/home/ParticleTrail";

function Aboutus(props) {
  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>About TrackGenesis&reg; | Vision Mission and Services </title>
        <meta
          name="title"
          content="About TrackGenesis | Vision Mission and Services"
        />
        <meta
          name="description"
          content="Our dedicated team of developers provide Blockchain solutions for the traceability of products with solid proof,nft for trading and providing ownership of digital arts,real estate assets and much more."
        />
        <link
          href="https://trackgenesis.com/about-us"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />
      </Helmet>
      {/* helmet */}

      <Flex
        backgroundColor={"black"}
        overflowX={"hidden"}
        flexDirection={"column"}
      >
        {/* heading section */}
        <Flex id="about">
          <Image
          alt="About TrackGenesis"
            ml={{ base: "-50px", md: "-40px", lg: "0px" }}
            width={{ base: "270px", md: "400px", lg: "500px" }}
            src={Aboutusheader}
          ></Image>
          <Text
            mt={"auto"}
            className="heading"
            fontSize={{ base: "30px", md: "45px", lg: "50px" }}
            ml={{ base: "-23%", md: "-16%", lg: "-10%" }}
            mb={{ base: "15px", md: "15px", lg: "30px" }}
            color={"#94DAF8"}
          >
            Who We Are
          </Text>
        </Flex>

        <ParticleTrail />

        <Navbar />
        <Mobilenavbar />

        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="4px"
        >
          {/* new vision mission */}
          <Flex
            my={{ base: "30px", md: "30px", lg: "30px" }}
            justifyContent={"center"}
          >
            <Flex>
              <Flex>
                <Image
                  objectFit={"contain"}
                  my={"auto"}
                  width={{ base: "350px", md: "350px", lg: "390px" }}
                  height={{ base: "240px", md: "480px", lg: "510px" }}
                  src={Visionnew}
                ></Image>
              </Flex>
              <Flex pr={"20px"} mt={"50px"} flexDir={"column"}>
                <Flex flexDir={"column"}>
                  <Text
                    className="heading"
                    ml={{ base: "-40px", md: "-40px", lg: "-30px" }}
                    color={"#94DAF8"}
                    fontSize={{ base: "15px", md: "18px", lg: "23px" }}
                  >
                    Our Vision
                  </Text>
                  <Text
                  mt={"10px"}
                    ml={{ base: "-10px", md: "-10px", lg: "0px" }}
                    fontSize={{ base: "11px", md: "12px", lg: "15px" }}
                    color={"white"}
                    className="content_font"
                    fontWeight={"light"}
                    maxW={{ base: "300px", md: "300px", lg: "500px" }}
                  >
                    Our vision is to create a better tomorrow and a sustainable
                    future by building transparency and trust through
                    innovation.
                  </Text>
                </Flex>
                <Flex
                  mt={{ base: "80px", md: "80px", lg: "110px" }}
                  flexDir={"column"}
                >
                  <Text
                    className="heading"
                    ml={{ base: "-40px", md: "-10px", lg: "10px" }}
                    color={"#94DAF8"}
                    fontSize={{ base: "15px", md: "18px", lg: "23px" }}
                  >
                    Our Mission
                  </Text>
                  <Text
                    mt={"10px"}
                    fontSize={{ base: "11px", md: "12px", lg: "15px" }}
                    color={"white"}
                    className="content_font"
                    fontWeight={"light"}
                    maxW={{ base: "340px", md: "300px", lg: "500px" }}
                    ml={{ base: "-20px", md: "0px", lg: "40px" }}
                  >
                    Uphold the values of Trust and Transparency with our
                    clients, employees, and society and be committed to
                    delivering exemplary outputs through innovation blended with
                    honesty, consistently without compromise in quality.
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex id="whatWeDo">
            {/* new what we do section */}
            <WhatWeDo />
          </Flex>
          {/* new our team section */}
          <Box id="ourTeam">
            <OurTeam />
            <Flex
              display={{ base: "block", md: "block", lg: "block" }}
              mt={"-25%"}
            >
              <Image objectFit={"contain"} width={"100%"} src={Teambg}></Image>
            </Flex>
          </Box>
          <Flex></Flex>
        </Flex>
        <ParticleBackground />
        <Footer />
      </Flex>
    </>
  );
}

export default Aboutus;
