import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Mobilenavbar from "../../../components/sections/Mobilenavbar";
import Navbar from "../../../components/sections/Navbar";
import Footer from "../../../components/sections/Footer";

import justinprofile from "../../../assets/images/BlogsPage/justinprofile.webp";
import blogtwo from "../../../assets/images/BlogsPage/blogtwo1.webp";
import blogimage from "../../../assets/images/BlogsPage/blogimg.webp";

import { Helmet } from "react-helmet";

import { Link } from "@chakra-ui/react";

function Blogpagetwo(props) {
  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>
          Blogs | Blockchain | TrackGenesis&reg; | Aberdeen Scotland
        </title>
        <meta
          name="title"
          content="Blogs |Blockchain | TrackGenesis | Aberdeen Scotland"
        />
        <meta
          name="description"
          content="Blockchain explained in simple terms"
        />

       
      </Helmet>

      {/* helmet */}
      <Flex
        flexDirection={"column"}
        backgroundColor={"black"}
        id="blog-whatisblockchain"
      >
        {/* heading Section */}
        <Flex
          backgroundColor={"black"}
          width="100%"
          flexDirection={"column"}
          alignItems="center"
          justifyContent={"center"}
          height={"400px"}
        >
          <Heading
            className="heading"
            color={"#94DAF8"}
            fontSize={{ md: "33px", base: "25px", lg: "37px" }}
            fontWeight="bold"
            textShadow="1px 1px #000000"
            mt={{ base: "36px", md: "35px", lg: "10px" }}
            textAlign="center"
            maxWidth={{ base: "80%", md: "65%", lg: "60%" }}
          >
            Blockchain Explained in Simple Terms
          </Heading>
        </Flex>

        <Navbar />
        <Mobilenavbar />

        <Flex
          backgroundColor={"black"}
          alignItems={"center"}
          justifyContent="center"
          width="100%"
        >
          <Image
            ml={{ base: "-5%", lg: "-5%" }}
            mt={{ base: "-90px", md: "-90px", lg: "-110px" }}
            width={{ base: "91%", md: "80%", lg: "70%" }}
            src={blogtwo}
          />
        </Flex>

        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
          my={{ base: "2%", md: "2%", lg: "2%" }}
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="8px"
          pb={"100px"}
        >
          <Flex flexDir={"column"}>
          <Flex
            color={"white"}
            className="content_font"
            width={"100%"}
            px={{ base: "10%", md: "15%", lg: "20%" }}
            fontSize={{ base: "15px", md: "16px", lg: "17px" }}
            flexDirection="column"
          >
            <Text mt={"30px"} className="content_font">
              Blockchain is the foundation of web 3.0, that transforms
              centralized data storage in the web's backend to decentralized
              data storage. Even now, the concept of blockchain is unknown to a
              large community.
            </Text>
            <Text
              as={"i"}
              mt={"30px"}
              className="content_font"
              fontWeight={"bold"}
              // color="rgba(42, 50, 73, 1)"
              fontSize={{ md: "19px", base: "19px", lg: "19px" }}
            >
              What is Blockchain?
            </Text>
            <Text mt={"30px"} className="content_font">
              To put it simply, Blockchain is a digital ledger that store data
              in the digital realm and allow different parties to exchange and
              access indelible/unchanged encrypted data inside the same network.
              Each block in the chain may include several transactions, and each
              new transaction is added to the most recent block in the chain.
              This creates an immutable record of all transactions that have
              taken place on the network.
            </Text>
            <Flex justifyContent={"center"}>
              <Image
                mt="30px"
                borderRadius={"5px"}
                width={{ base: "70%", md: "60%", lg: "60%" }}
                src={blogimage}
              ></Image>
            </Flex>
            <Text mt={"30px"} className="content_font">
              Transactions are encrypted using a complex algorithm and stored as
              64-bit hash values in each block. A copy of hash value of each
              block is added to the succeeding block and that’s how it forms a
              chain. If anyone tamper the data in block 1, the hash value
              changes. As a copy of hash value of block 1 is stored in block 2,
              tampering the data is nearly impossible.
            </Text>
            <Text mt={"30px"} className="content_font">
              Transactions are validated using{" "}
              <Link href="https://www.techtarget.com/whatis/definition/consensus-algorithm#:~:text=A%20consensus%20algorithm%20is%20a,involving%20multiple%20users%20or%20nodes.">
                {" "}
                <Text fontWeight={"bold"} as="i">
                  Consensus algorithm.
                </Text>
              </Link>{" "}
              Consensus mechanism finds the node that can validate the
              transaction and create new block.
            </Text>

            <Text
              as={"i"}
              mt={"30px"}
              className="content_font"
              fontWeight={"bold"}
              // color="rgba(42, 50, 73, 1)"
              fontSize={{ md: "19px", base: "19px", lg: "19px" }}
            >
              Blockchain is Tamper-proof
            </Text>
            <Text mt={"30px"} className="content_font">
              {" "}
              <Text fontWeight={"bold"} as="i">
                Data that has been fed into a block can never be removed or
                modified.
              </Text>{" "}
              If we modify any data recorded in the blockchain network, the
              amended data will appear as a new block. The data initially
              recorded remains unaltered.
            </Text>

            <Text
              as={"i"}
              mt={"30px"}
              className="content_font"
              fontWeight={"bold"}
              // color="rgba(42, 50, 73, 1)"
              fontSize={{ md: "19px", base: "19px", lg: "19px" }}
            >
              Blockchain is Decentralized
            </Text>

            <Text mt={"30px"} className="content_font">
              Traditional systems are centralised and vulnerable to fraudulent
              activities. As data is maintained by a single person or firm,
              anybody may commit fraud to any level and go unreported.
            </Text>
            <Text mt={"30px"} className="content_font">
              <Text fontWeight={"bold"} as="i">
                Blockchain is a decentralized technology that allows all network
                members to view and share data in real time.
              </Text>{" "}
              Even attempting to edit or erase data in the blockchain network is
              impossible since each piece of data are stored on numerous
              computers.
            </Text>

            <Text mt={"30px"} className="content_font">
              Blockchain can revolutionize any business, from supply chain and
              inventory management to product provenance, regardless of
              industry. Blockchain-created transparent and tamper-proof records
              always assist organizations to win customer loyalty.
            </Text>
          </Flex>
          <Flex my={"20px"} width={"100%"}>
            <Flex ml={{ base: "40%", md: "65%", lg: "65%" }}>
              <Text
               color={"white"}
               className="content_font"
               fontSize={{ md: "23px", base: "15px", lg: "25px" }}
              >
                - by Justin J Daniel
              </Text>
              <Image
                 objectFit={"contain"}
                 ml={{ base: "10px", md: "10px", lg: "30px" }}
                 mt={"-10px"}
                 width={{ base: "30px", md: "40px", lg: "55px" }}
                src={justinprofile}
              />
            </Flex>
          </Flex></Flex>
        </Flex>
        <Footer />
      </Flex>
    </>
  );
}

export default Blogpagetwo;
