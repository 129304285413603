/** @format */

import React, { useState, useEffect } from "react";
import { Flex, Box, Center, Image } from "@chakra-ui/react";
import "./splash.css";
// import Logo from "../../assets/images/HomePage/HatLogo.svg";
import Skip from "../../assets/images/HomePage/skip.webp";
import "animate.css";

import DesktopIntroVideo from "../../assets/images/HomePage/DesktopIntro.mp4";
import MobileIntroVideo from "../../assets/images/HomePage/MobileIntro.mp4";
import { HashLink } from "react-router-hash-link";

function TrackGenSplash() {
  // for mobile and desktop video
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const videoSourceIntro = isMobile ? MobileIntroVideo : DesktopIntroVideo;

  const [anime, setAnime] = useState(false);
  setTimeout(() => {
    setAnime(true);
  }, 300);

  return (
    <Flex backgroundColor={"black"} height={"100vh"}>
      <HashLink smooth to={"/home#homepage"}>
        <Image
          alt="skip to content button"
          zIndex={3}
          opacity={{ base: 0.9, md: 0.9, lg: 0.7 }}
          width={{ base: "100px", md: "120px", lg: "150px" }}
          position="fixed"
          objectFit={"contain"}
          cursor={"pointer"}
          _hover={{
            opacity: 1,
            transform: "scale(1.02)",
            transition: "opacity 0.2s, transform 0.2s ease-in-out",
          }}
          top="2"
          right={2}
          src={Skip}
        ></Image>
      </HashLink>
      <video
        width="600"
        height="100%"
        autoPlay
        muted
        playsInline
        src={videoSourceIntro}
        className="videolanding"
      ></video>
    </Flex>
  );
}

export default TrackGenSplash;
