import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Mobilenavbar from "../../../components/sections/Mobilenavbar";
import Navbar from "../../../components/sections/Navbar";
import Footer from "../../../components/sections/Footer";
import { ListItem, UnorderedList, Link } from "@chakra-ui/react";
import chrisblog from "../../../assets/images/BlogsPage/chrisblog.webp";
import blogthree from "../../../assets/images/BlogsPage/palmoilmain.webp";

import { Helmet } from "react-helmet";

function Blogpagefive(props) {
  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>
          Blogs | Cryptocurrency | TrackGenesis&reg; | Aberdeen Scotland
        </title>
        <meta
          name="title"
          content="Blogs | PalmOil | TrackGenesis | Aberdeen Scotland"
        />
        <meta
          name="description"
          content="Blockchain Technology for Palm Oil Traceability"
        />
        <link
          href="https://trackgenesis.com/blogs"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />
       
      </Helmet>
      {/* helmet */}
      <Flex
        backgroundColor={"black"}
        flexDirection={"column"}
        id="blog-palmoil"
      >
        {/* heading Section */}
        <Flex
          backgroundColor={"black"}
          width="100%"
          flexDirection={"column"}
          alignItems="center"
          justifyContent={"center"}
          height={"400px"}
        >
          <Heading
            className="heading"
            color={"#94DAF8"}
            fontSize={{ md: "33px", base: "25px", lg: "37px" }}
            fontWeight="bold"
            textShadow="1px 1px #000000"
            mt={{ base: "36px", md: "35px", lg: "10px" }}
            textAlign="center"
            maxWidth={{ base: "80%", md: "65%", lg: "60%" }}
          >
            Blockchain Technology for Palm Oil Traceability: A Game-Changer in
            Sustainability
          </Heading>
        </Flex>

        <Navbar />
        <Mobilenavbar />

        <Flex
          backgroundColor={"black"}
          alignItems={"center"}
          justifyContent="center"
          width="100%"
        >
          <Image
            mt={{ base: "-90px", md: "-90px", lg: "-110px" }}
            ml={{ base: "-5%", lg: "-5%" }}
            width={{ base: "91%", md: "80%", lg: "70%" }}
            src={blogthree}
          />
        </Flex>

        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
          my={{ base: "2%", md: "2%", lg: "2%" }}
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="8px"
          pb={"100px"}
        >
          <Flex flexDir={"column"}>
            <Flex
              color={"white"}
              className="content_font"
              width={"100%"}
              px={{ base: "10%", md: "15%", lg: "20%" }}
              fontSize={{ base: "15px", md: "16px", lg: "17px" }}
              flexDirection="column"
            >
              <Text mt={"30px"} className="content_font">
                In recent years, the global demand for palm oil has surged,
                driven by its versatile applications in various industries.
                However, the palm oil industry faces significant challenges
                related to environmental sustainability and human rights
                violations. To address these concerns and ensure transparency,
                accountability, and traceability, blockchain technology is
                emerging as a game-changer. This revolutionary technology
                provides a decentralized and immutable ledger that enables
                seamless tracking of palm oil production, transportation, and
                supply chain management.  
              </Text>
              <Text mt={"30px"} className="content_font">
                The Roundtable on Sustainable Palm Oil (RSPO) strives to enforce
                optimal practices in palm oil production and trade, and major
                brands have made commitments to exclusively utilize 100%
                RSPO-certified palm oil and oil products. However, despite these
                commendable endeavours, only 17% of worldwide palm oil
                production currently holds sustainable certification. As a
                consequence, the supply chain remains somewhat obscure, posing
                challenges for regulatory bodies in determining the authenticity
                of sustainable practices by producers and for consumers in
                identifying ethical products with certainty.  
              </Text>
              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"20px"}
              >
                Understanding the Challenges in Palm oil Traceability
              </Text>
              <Text mt={"30px"} className="content_font">
                The palm oil industry has long been associated with
                deforestation, habitat destruction, and labour abuses. Tracking
                the origin of palm oil and verifying its sustainability claims
                has been a complex task due to the involvement of multiple
                stakeholders across the supply chain. Conventional systems lack
                transparency, making it challenging for consumers, companies,
                and regulatory bodies to ensure ethical and sustainable
                practices. Blockchain technology offers a solution by creating a
                secure and tamper-proof system that enables real-time
                monitoring, auditing, and traceability of palm oil from
                plantation to end-user.
              </Text>
              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"20px"}
              >
                Blockchain Workflow for Palm Oil Traceability
              </Text>
              <Text mt={"30px"} className="content_font">
                To better understand the implementation of blockchain in palm
                oil traceability, let's examine a typical workflow diagram:
              </Text>
              <Text mt={"30px"} className="content_font">
                <Text fontWeight={"bold"} as="i">
                  Data Collection:{" "}
                </Text>
                Data on palm oil production, including plantation details,
                yields, and certifications, is collected from various sources
                such as farmers, mills, and auditors. (This include geo-tagging,
                growers’ details, land permits, etc.) This information is stored
                in a decentralized blockchain network.
              </Text>
              <Text mt={"30px"} className="content_font">
                <Text fontWeight={"bold"} as="i">
                  Verification and Smart Contracts:{" "}
                </Text>
                Smart contracts are utilized to validate the collected data,
                ensuring compliance with sustainability standards and
                certifications. Once verified, the information is added to the
                blockchain, creating an immutable record.
              </Text>
              <Text mt={"30px"} className="content_font">
                <Text fontWeight={"bold"} as="i">
                  Supply Chain Management:{" "}
                </Text>
                As palm oil moves through the supply chain, each stage,
                including processing, transportation, and distribution, is
                recorded on the blockchain. This enables stakeholders to track
                the product's journey, ensuring transparency and traceability.
              </Text>
              <Text mt={"30px"} className="content_font">
                <Text fontWeight={"bold"} as="i">
                  Auditing and Certification:{" "}
                </Text>
                Independent auditors can access the blockchain to verify the
                authenticity of sustainability claims and certifications. This
                promotes trust and accountability within the industry and
                encourages ethical practices.
              </Text>
              <Text mt={"30px"} className="content_font">
                <Text fontWeight={"bold"} as="i">
                  Consumer Engagement:{" "}
                </Text>
                Blockchain technology allows consumers to access detailed
                information about the palm oil they purchase. By scanning QR
                codes or using mobile apps, consumers can view the product's
                origin, sustainability credentials, and social impact,
                empowering them to make informed choices.
              </Text>

              <Text mt={"30px"} className="content_font">
                The IT systems across the value chain would be configured to
                exclude any palm oil which doesn’t carry a digital identity.
                Ultimately, it would involve collaborating with companies to
                introduce innovative packaging that engages consumers, enabling
                them to gain a deeper understanding of the product's
                sustainability and fostering increased awareness among
                consumers.
              </Text>
              <Text mt={"30px"} className="content_font">
                The Roundtable on Sustainable Palm Oil has recognized the
                potential of blockchain technology in improving palm oil
                traceability. RSPO is exploring blockchain solutions to enhance
                their certification process, enabling more efficient tracking
                and verification of sustainable palm oil.
              </Text>
              <Text mt={"30px"} className="content_font">
                Palm Oil Blockchain has the potential to bridge the gap between
                end consumers and growers, facilitating more meaningful
                interactions between consumers, brand owners, and palm oil
                producers. The primary objective is to reduce the influence of
                existing intermediaries, such as traders, mill owners, and
                refiners, by minimizing their "network power" within the palm
                oil value chain.
              </Text>
              <Text mt={"30px"} className="content_font">
                By introducing this "consumer visibility" into the current
                supply chains, it becomes possible to transition towards a more
                sustainable and responsible approach to consumerism. For
                companies that directly engage with consumers, this becomes an
                opportunity to establish a competitive advantage based on
                sustainability, while also advocating for a deforestation-free
                commodity supply chain.
              </Text>
            </Flex>
            <Flex my={"20px"} width={"100%"}>
              <Flex ml={{ base: "40%", md: "65%", lg: "65%" }}>
                <Text
                  color={"white"}
                  className="content_font"
                  fontSize={{ md: "23px", base: "15px", lg: "25px" }}
                >
                  - by Christina Thomas
                </Text>
                <Image
                  objectFit={"contain"}
                  ml={{ base: "10px", md: "10px", lg: "30px" }}
                  mt={"-10px"}
                  width={{ base: "30px", md: "40px", lg: "55px" }}
                  src={chrisblog}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Footer />
      </Flex>
    </>
  );
}

export default Blogpagefive;
