import { Flex, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import Tokengif from "../../../assets/images/ProductsPage/Gifs/Token Distribution.gif";
import CommonButton from "../../../components/CommonButton.js";

import Underline from "../../../assets/images/HomePage/underline.webp";
function TokenDistribution() {
  return (
    // new code
    <Flex
      id="tokendistribution"
      mt={{ base: "40px", md: "80px", lg: "200px" }}
      flexDir={"column"}
      width={"100%"}
    >
      {/* title */}
      <Flex my={{ base: "30px", md: "10px", lg: "10px" }} mx={"auto"}>
        <Image
          mt={{ base: "6px", lg: "15px" }}
          height={"40px"}
          width={"70px"}
          src={Underline}
          alt="underline"
        ></Image>
        <Text
          className="heading"
          color={"#94DAF8"}
          fontSize={{ base: "24px", md: "38px", lg: "45px" }}
        >
          Token Distribution Platform
        </Text>
        <Image
          mt={{ base: "6px", lg: "15px" }}
          height={"40px"}
          width={"70px"}
          src={Underline}
          alt="underline"
        ></Image>
      </Flex>
      {/* content */}
      <Flex
        flexDir={{ base: "column-reverse", md: "column-reverse", lg: "row" }}
        mx={"auto"}
        width={{ base: "100%", md: "90%", lg: "80%" }}
        mt={{ base: "10px", md: "40px", lg: "70px" }}
      >
        <Flex
          ml={{ base: "0px", md: "0px", lg: "0px" }}
          p={{ base: "10px", md: "10px", lg: "30px" }}
          mt={{ base: "40px", md: "40px", lg: "0px" }}
          width={{ base: "100%", md: "100%", lg: "50%" }}
        >
          <Flex
            width={"100%"}
            px={"40px"}
            height={"fit-content"}
            mx={"auto"}
            my={"auto"}
            flexDir={"column"}
          >
            {" "}
            <Text
              width={{ base: "100%", md: "100%", lg: "100%" }}
              fontSize={{ base: "14px", md: "14px", lg: "16px" }}
              color={"white"}
              className="content_font"
              fontWeight={"light"}
            >
              A web application simplifies ERC-20 token distribution for
              companies by eliminating the need for wallets and navigating web3
              complexities.Token Distribution Platform Facilitates the
              distribution and handling of ERC-20 tokens for users, removing the
              necessity of dealing with intricate wallets and web3 complexities.
              Compatible with legacy systems, it presents a perfect option for
              dispensing loyalty rewards, carbon credits, custom tokens, and
              beyond.
            </Text>
            <HashLink smooth to={"/contact-us#contact"}>
              <CommonButton
                width="100px"
                btn="Book a Demo"
                mt={{ base: "30px", md: "40px", lg: "30px" }}
              />
            </HashLink>
          </Flex>
        </Flex>

        <Flex
          px={{ base: "20px", md: "30px", lg: "20px" }}
          mt={{ base: "40px", md: "40px", lg: "0px" }}
          width={{ base: "100%", md: "100%", lg: "50%" }}
        >
          <Flex
            mx={"auto"}
            my={"auto"}
            borderRadius={"10px"}
            width={{ base: "400px", md: "500px", lg: "650px" }}
          >
            <Flex
              boxShadow="0px 0px 12.7px 0px #9EF395"
              mt={{ base: "-10px", md: "-10px", lg: "-10px" }}
              borderRadius={{ base: "40px", md: "50px", lg: "70px" }}
              zIndex={-1}
              border={"1px solid #9EF395"}
            >
              <Flex>
                <Image src={Tokengif}></Image>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default TokenDistribution;
