import {  Flex, Heading, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Mobilenavbar from "../../components/sections/Mobilenavbar";
import Navbar from "../../components/sections/Navbar";
import Footer from "../../components/sections/Footer";

import { Helmet } from "react-helmet";

import Blogbox from "../../components/sections/Blog/Blogbox";
import Blogsheader from "../../assets/images/BlogsPage/blogsheader.webp";
import FAQ from "../../components/sections/Blog/FAQ";
import ParticleBackground from "../../components/sections/home/ParticleBackground";
import ParticleTrail from "../../components/sections/home/ParticleTrail";
function Blogs(props) {
  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>
        Blogs | TrackGenesis® | Aberdeen Scotland 
        </title>
        <meta
          name="title"
          content="Blockchain, NFT, AR/VR & Software Development Blogs | TrackGenesis®️ | Scotland UK "
        />
        <meta
          name="description"
          content="Read our blogs to get updates on latest happenings in the web 3.0 ecosystem. Get industry-specific insights, ideas and inspiration for your complete digital transformation."
        />
        <link
          href="https://trackgenesis.com/blogs"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />
    
              {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=UA-197747980-1"
    ></script>
      </Helmet>
      {/* helmet */}
      <Flex flexDirection={"column"} backgroundColor={"black"} id="blogs">
        <Flex>
          <Image
               ml={{base:"-50px",md:"-40px",lg:"0px"}}
            width={{ base: "270px", md: "400px", lg: "500px" }}
            src={Blogsheader}
          ></Image>
          <Text
            mt={"auto"}
            className="heading"
          
            mb={{base:"15px",md:"15px",lg:"30px"}}
            color={"#94DAF8"}
            fontSize={{ base: "30px", md: "45px", lg: "50px" }}
            ml={{ base: "-15%", md: "-10%", lg: "-5%" }}
          >
            Blogs
          </Text>
        </Flex>
      

        <Navbar />
        <Mobilenavbar />
      
        {/* new code */}
   
      <ParticleTrail/>
        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
       
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="4px"
          pb={"100px"}
        >
         

          {/* Blog boxes */}
          <Flex id="blogbox" mx={"auto"} width={{ base: "100%", md: "100%", lg: "80%" }}>
            <Blogbox />
          </Flex>

          {/* FAQ */}
          <Flex id="faqs">
        <FAQ/>
        </Flex>


        </Flex>
        <ParticleBackground />
        <Footer />
      </Flex>
    </>
  );
}

export default Blogs;
